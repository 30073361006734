import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

const Navigation = () => {
	const myStyle = {
		backgroundColor: "#1B1B1E",
		borderColor: "#1B1B1E",
		color: "#CED9DF",
	};

	return (
		<Navbar style={myStyle} variant="dark">
			<div className="Navigation">
				<Nav>
					<Navbar.Brand as={Link} to="/" style={myStyle}>
						Jake Tockerman
					</Navbar.Brand>
					{/*<Nav.Link as={Link} to = "/about" style={myStyle}>About</Nav.Link>*/}
					{/* <Dropdown>
						<Dropdown.Toggle style={myStyle}>
							Projects
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item
								as={Link}
								to="/projects/ece-362-mini-project"
							>
								ECE 362 Mini Project
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown> */}
					<Nav.Link as={Link} to="/shuffle" style={myStyle}>
						Shuffle
					</Nav.Link>
				</Nav>
			</div>
		</Navbar>
	);
};

export default Navigation;
